export default {
    name: "Удон",
    anchor: "udon",
    items: [
      {
        name: "Удон с курицей",
        img: "/images/rolli/photo_2023-12-25_01-40-20.jpg",
        alt: "",
        desc: "Лапша удон, морковь, перец болгарский, лук, курица, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [280],
        size: null,
      },
      {
        name: "Удон со свининой",
        img: "/images/rolli/photo_2023-12-25_01-40-20.jpg",
        alt: "",
        desc: "Лапша удон, морковь, перец болгарский, лук, свинина, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [330],
        size: null,
      },
      {
        name: "Удон с говядиной",
        img: "/images/rolli/photo_2023-12-25_01-40-20.jpg",
        alt: "",
        desc: "Лапша удон, морковь, перец болгарский, лук, говядина, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [370],
        size: null,
      },
      {
        name: "Удон морской",
        img: "/images/rolli/photo_2023-12-25_01-40-19.jpg",
        alt: "",
        desc: "Лапша удон, морковь, перец болгарский, лук, морской коктейль, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [349],
        size: null,
      },
      {
        name: "Тяхан с курицей",
        img: "/images/rolli/photo_2023-12-25_01-40-18 (2).jpg",
        alt: "",
        desc: "Рис, морковь, перец болгарский, лук, курица, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [280],
        size: null,
      },
      {
        name: "Тяхан со свининой",
        img: "/images/rolli/photo_2023-12-25_01-40-18 (2).jpg",
        alt: "",
        desc: "Рис, морковь, перец болгарский, лук, свинина, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [330],
        size: null,
      },
      {
        name: "Тяхан с говядиной",
        img: "/images/rolli/photo_2023-12-25_01-40-18 (2).jpg",
        alt: "",
        desc: "Рис, морковь, перец болгарский, лук, говядина, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [370],
        size: null,
      },
      {
        name: "Тяхан морской",
        img: "/images/rolli/photo_2023-12-25_01-40-18.jpg",
        alt: "",
        desc: "Рис, морковь, перец болгарский, лук, морской коктейль, чеснок, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
        price: [349],
        size: null,
      },
    ],
  };
  